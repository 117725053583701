import React from 'react'
import { Helmet } from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'

const Contact = ({ data }) => {
  const postNode = {
    title: `Contato - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title lang={config.language}>Contato - Cashback Ranking - Compare cashbacks com a nossa extensão</title>

        <meta charset="utf-8"></meta>
        <link rel="canonical" href="https://www.cashbackranking.com.br/contato/" />
        <meta
          name="description"
          content="Contato - Compare cashback no Cashback Ranking: As melhores ofertas da internet! - Extensão Cashback Ranking"
        />
        <meta httpEquiv="content-language" content="pt-br" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"></meta>
        <meta name="HandheldFriendly" content="True"></meta>
        <meta name="MobileOptimized" content="320"></meta>
      </Helmet>

      <SEO postNode={postNode} pagePath="contato" customTitle />

      <Container>
        <PageTitle>Contato</PageTitle>
        <ContactForm />
      </Container>
    </Layout>
  )
}

export default Contact
